const articlesData = [
  {
    date: { year: 2025, month: 3, day: 30 },
    title: "四、五、六月門診異動",
    content: [
      "1. 清明連假4/3（四）、4/5（六）只有上午門診，4/4（五）全日休診",
      "2. 4/14（一）~ 4/22（二）沈院長出國，由蕭醫師代診",
      "3. 5/30（五）~ 6/2（一）員工旅遊，全日休診",
    ],
  },
  {
    date: { year: 2025, month: 2, day: 16 },
    title: "二月門診異動",
    content: ["1. 2/28（五）國定假日全日休診"],
  },
  {
    date: { year: 2025, month: 1, day: 21 },
    title: "一月門診異動",
    content: [
      "1. 1/27（一）只有早診，由蕭醫師看診",
      "2. 1/28（二）~ 2/2（日）全日休診，2/3（一）恢復正常門診",
    ],
  },
  {
    date: { year: 2024, month: 12, day: 14 },
    title: "十二月門診異動",
    content: ["1. 12/24（二）～12/28（六）沈院長出國，由蕭醫師代診", "2. 1/1（三）元旦，全日休診"],
  },
  {
    date: { year: 2024, month: 9, day: 5 },
    title: "九月十月門診異動",
    content: [
      "1. 中秋節9/17全時段休診",
      "2. 國慶雙十連假10/10全時段休診",
      "3. 10/11沈院長全日門診由蕭醫師代診",
    ],
  },
  {
    date: { year: 2024, month: 8, day: 26 },
    title: "八月門診異動",
    content: [
      "本診所已遷移到捷運紅樹林站正對面",
      "1. 8/28-9/3沈院長出國，由蕭醫師代理看診",
      "2. 中秋連假看診時間： 9/14、9/16、9/17都只有上午門診",
    ],
  },
  {
    date: { year: 2024, month: 7, day: 5 },
    title: "七月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 7/18（四）早診只掛號至11:00  午、晚診正常看診",
      "2. 7/19（五）-7/22（ㄧ）沈院長出國，由蕭醫師代診",
    ],
  },
  {
    date: { year: 2024, month: 4, day: 11 },
    title: "四、五月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 4/18、4/30沈院長請假，由蕭醫師代診。",
      "2. 本診所將搬遷至淡水區中正東路二段75號1、2F（紅樹林捷運站正對面），診所於5/1-5/8停診準備搬遷事宜，5/9正式開幕。不便之處，敬請見諒。",
    ],
  },
  {
    date: { year: 2024, month: 3, day: 25 },
    title: "三、四月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 3/29星期五沈院長請假，由蕭醫師代診，3/30星期六休診。",
      "2. 4/4、5、6 清明連假只有上午門診。",
    ],
  },
  {
    date: { year: 2024, month: 2, day: 9 },
    title: "二月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 春節期間2/8只看早診及午診。",
      "2. 2/9至2/14休診。",
      "3. 2/15恢復正常門診。",
      "4. 2/16沈院長出國由蕭醫師代診。",
      "5. 2/28只看早診。",
    ],
  },
  {
    date: { year: 2024, month: 1, day: 13 },
    title: "診所搬遷公告，搬遷日期待定。",
    content: ["由於租約到期，本診所將搬遷，確實地點及搬遷日期將會再公布。"],
  },
  {
    date: { year: 2023, month: 9, day: 29 },
    title: "十月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 10/6星期五沈院長只看早診，下午及晚診由蕭醫師代診。",
      "2. 雙十連假10/9及10/10只看早診，由蕭醫師看診。",
      "3. 10/5下午，10/14早診，10/26午診，10/28早診盧醫師請假，由蕭醫師代診。",
      "4. 10/19晚診，10/23晚診蕭醫師請假，由其他醫師代診。",
    ],
  },
  {
    date: { year: 2023, month: 9, day: 4 },
    title: "九月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 9/15星期五沈院長請假，門診由蕭醫師代診。",
      "2. 9/18、9/20、9/21、9/25、9/28蕭醫師請假，代診醫師看診",
      "3. 9/23盧醫師請假，由蕭醫師代診。",
      "4. 9/27晚上休診。",
      "5. 中秋連假9/29、9/30只有早診。",
    ],
  },
  {
    date: { year: 2023, month: 8, day: 16 },
    title: "八月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 8/19星期六早診，8/24星期四下午診盧醫師請假，門診由蕭醫師代診。",
      "2. 8/23星期三至8/30星期三沈院長出國，門診由蕭醫師代診。",
    ],
  },
  {
    date: { year: 2023, month: 7, day: 7 },
    title: "七月門診異動。不便之處，敬請見諒。",
    content: [
      "1. 7/12星期三至7/14星期五沈院長出國，門診由蕭醫師代診。",
      "2. 7/13星期四下午診，7/15星期五早診，7/27星期四下午診盧醫師請假，由蕭醫師代診。",
    ],
  },
  {
    date: { year: 2023, month: 5, day: 18 },
    title: "門診異動。不便之處，敬請見諒。",
    content: [
      "1. 5/26星期五至6/5星期一沈院長出國，由蕭醫師代診。",
      "2. 5/25星期四至5/30星期二盧醫師出國，由蕭醫師代診。",
      "3. 5/25星期四晚診停診。",
    ],
  },
  {
    date: { year: 2023, month: 3, day: 25 },
    title: "2023年4月門診異動",
    content: ["1. 4/1，4/3，4/5均只有上午門診。", "2. 4/4整天休診，4/6晚上休診。"],
  },
  {
    date: { year: 2023, month: 2, day: 20 },
    title: "2023年228連續假期",
    content: ["2/25，2/27，2/28均只有上午門診。"],
  },
  {
    date: { year: 2023, month: 1, day: 1 },
    title: "2023年1月門診休診時間",
    content: ["1/2全日休診。1/21至1/25全日休診。1/20，1/26，1/27只有上午門診。"],
  },
  {
    date: { year: 2022, month: 9, day: 25 },
    title: "十月門診異動",
    content: [
      "1. 10/8 及 10/10 只有早上門診，10/9 全日休診",
      "2. 10/12 至 10/14 沈院長請假，由蕭醫師代診",
    ],
  },
  {
    date: { year: 2022, month: 9, day: 10 },
    title: "九月門診異動",
    content: ["1. 9/13 至 9/23 沈院長請假，由蕭醫師代診", "2. 9/29晚上休診"],
  },
  {
    date: { year: 2022, month: 8, day: 25 },
    title: "2022中秋連假休診",
    content: ["中秋節連假，9/9只有上午門診，9/10及9/11全日休診"],
  },
  {
    date: { year: 2022, month: 5, day: 26 },
    title: "2022端午節連假休診",
    content: ["端午節連假，6/3-5均休診"],
  },
  {
    date: { year: 2022, month: 3, day: 26 },
    title: "2022年清明連假",
    content: ["4/2，4/4 只有上午門診 (11:30停止掛號)， 4/3，4/5全日休診"],
  },
];

export default articlesData;
